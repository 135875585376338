.climateControl {
    position: absolute;
    top: 2px;
    right: 2px;
    border-radius: 5px;

    .btn {
        padding: 0 0;
        width:40px;
        height:38px;
        font-size: 0.7rem;

        &.cold{background: linear-gradient(225deg, #343a40 0%,#343a40 30%,#314b6e 70%,#314b6e 100%);}
        &.hot{background: linear-gradient(45deg, #343a40 0%,#343a40 30%,#6c3729 70%,#6c3729 100%);}


        .ecoSet {
            float:left;
            width:19px;
                color:lightblue;
        }

        .comfortSet {
            float:left;
            width:19px;
                color:indianred;
        }

        .operationMode {
            color:white;
            padding: 1px;
        }
    }
}

.blink {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}