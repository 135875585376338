nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 2.5em;
    padding-top: 8px;

    .fa-power-off {
        color: red;
    }

    @include media-breakpoint-up(md) {
        width: 3em;
        height: 100%;
    }

    .row {
        margin-left: 0;
        margin-right: 0;

        .col {
            padding-right: 0;
            padding-left: 0;
            @media (min-width: $tablet) {
                padding-right: 10px;
                padding-left: 10px;
            }
            a {
                color: $primaryBackground;
            }
            .nav-link {
                padding: 0;
            }
            .nav-link.isActive {
                color: #28a745;
            }

            @include media-breakpoint-up(md) {
                padding-right: 10px;
                padding-left: 10px;
                margin-bottom: 15px;
            }



        }
    }
}

.fa-rotate-45 {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(15deg);
}
