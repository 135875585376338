.shoppingItemForm {
    padding-bottom: 5px;
    input, button {
        margin: 5px 0 5px 0;
    }
}

.shoppingItem {
    border: 1px solid #ccc;
    padding: 5px 3px 5px 3px;
    margin: 0 0 5px 0;

    .col, .col-sm-1 {
        width: auto;
    }

    .col {
        padding-left: 5px;
        padding-right: 5px;
    }


    .status {
        border-left: 1px dotted #ccc;

        .fa-check-circle {
            color: green;
        }

        .fa-undo {
            color: red;
        }
    }
}

.changeListViewItems {
    background: black;
    display: flex;
    flex-wrap: wrap;
    position: fixed;
    bottom: 0px;
    left: 15px;
    width: 100%;
    height: 2.5em;
    padding-bottom: 0;
    border-top: 1px solid #ccc;
    background: linear-gradient(to bottom, rgba(248, 249, 250, 0.85) 0%, rgba(52, 58, 64, 1) 30%, rgba(52, 58, 64, 1) 100%);
    div {
        line-height: 2rem;
    }

    @media (min-width: $tablet) {
        left: 3em;
        margin-left: 0px;
        width: calc(100% - 3em - 15px);
        div {
            line-height: 2.5rem;
        }
    }


}