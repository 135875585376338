.waste {
    font-size: 2rem;
    text-align:center;
    .residual i {
        color: grey;
    }
    .paper i.sc-outer {
        color: green;
    }
    .paper i.sc-inner {
        color: white;
        font-size: 0.8rem;
    }
    .plastic i.sc-outer {
        color: yellow;
    }
    .plastic i.sc-inner {
        color: black;
        font-size: 0.8rem;
    }
    .glass i.sc-outer {
        color: blue;
    }
    .glass i.sc-inner {
        color: white;
        font-size: 0.8rem;
    }
    .wasteDate {
        font-size:0.7rem;
        box-shadow: 4px 4px 8px rgba(255,255,255, .2);
        padding: 2px;
        margin-bottom: 5px;
        &.wasteDateWarning {
            color: orange;
            box-shadow: 4px 4px 8px rgba(255,125,0, .4);
        }
        &.wasteDateAlert {
            color: red;
            box-shadow: 4px 4px 8px rgba(255,0,0, .4);

        }
    }
    @media (min-width: $tablet) {
        font-size: 3rem;
        .wasteDate {
            font-size:1rem;
        }
    }
}

.dates {
    font-size: 2rem;
    text-align:center;
    @media (min-width: $tablet) {
        font-size: 3rem;
    }
}

.dateWrapper {
    text-align:left;
    width: 100%;
    margin-bottom:5px;
    padding:3px;
    font-size:0.7rem;
    background-color: #32383e;
    //border: 1px solid #DDD;
    box-shadow: 4px 4px 8px rgba(255,255,255, .2);
    @media (min-width: $tablet) {
        font-size: 1rem;
    }
}

.dateWrapper.dateWarning {
    box-shadow: 4px 4px 8px rgba(255,125,0, .4);
}

.dateWrapper.dateAlert {
    box-shadow: 4px 4px 8px rgba(255,0,0, .4);
}