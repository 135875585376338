.room-icon-position{
  background-size: 25px 25px;
  background-position: center center;
  background-repeat: no-repeat;
}

.livingroom {
  @extend .room-icon-position;
  background-image: url(../../../assets/2095568.png);
}

.livingroom {
  @extend .room-icon-position;
  background-image: url(../../../assets/2095568.png);
}

.kitchen {
  @extend .room-icon-position;
  background-image: url(../../../assets/1606731.png);
}

.hallway {
  @extend .room-icon-position;
  background-image: url(../../../assets/1494700.png);
  background-position: calc(50% + 10px) 50%;
}

.sleepingroom {
  @extend .room-icon-position;
  background-image: url(../../../assets/895349.png);
}

.floodlight {
  @extend .room-icon-position;
  background-image: url(../../../assets/186520.png);
}

.bathroom {
  @extend .room-icon-position;
  background-image: url(../../../assets/1505092.png);
}

.balcony {
  @extend .room-icon-position;
  background-image: url(../../../assets/2114225.png);
}

.laundry {
  @extend .room-icon-position;
  background-image: url(../../../assets/washing-machine.png);
}

.stairway {
  @extend .room-icon-position;
  background-image: url(../../../assets/stairs.png);
}