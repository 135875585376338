//.row {
//    margin-right: 0;
//    margin-left: 0;
//}
//.loginForm.row {
//    width: 100%;
//    padding: 0 10px 0 10px;
//    padding-top: 50px;
//    @media (min-width: $tablet){
//        width: 50%;
//        margin:0 auto;
//    }
//}

.login {
    .container {
        @include media-breakpoint-up(md) {
            max-width: 540px;
        }
    }

    .card {
        border: none;
    }

    .loginHeadline {
        margin-top:50px;
        margin-bottom:45px;
    }

}

